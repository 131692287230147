<template>
  <div class="cont" @scroll="scrollBottom($event)">
    <div id="homePage">
      <TopBanner />
      <template
        v-if="userName != null && userName != undefined && userName != ''"
      >
        <div class="page1" data-ratio="page1">
          <div class="user">
            <van-image
              round
              width="18.67%"
              height="44.87%"
              :src="userImage"
              class="img"
            />
            <div class="txt1">{{ userName }}</div>
            <div class="txt2">
              <span>{{ userFrom.provinceName }}{{ userFrom.cityName }}{{ userFrom.areaName }}</span>
              <span class="txt3">{{ userAge }}</span>
              <span>
                <span v-if="userSex == '男'"
                  ><img
                    src="../../assets/record/record_page1_sex1.png"
                    width="10px"
                /></span>
                <span v-else
                  ><img
                    src="../../assets/record/record_page1_sex2.png"
                    width="10px"
                /></span>
                <span style="padding-left: 3px">{{ userSex }}</span>
              </span>
            </div>
            <div class="txt5">个人ID：{{ userId }}</div>
          </div>
          <div class="grnum fs">
            <div class="num">{{ fsNum }}</div>
            <div class="txt">粉丝</div>
          </div>
          <div class="grnum gz">
            <div class="num">{{ gzNum }}</div>
            <div class="txt">关注</div>
          </div>
          <div class="grnum hz">
            <div class="num">{{ hzNum }}</div>
            <div class="txt">获赞</div>
          </div>
          <div class="border"></div>
        </div>
        <div class="page2">
          <pre>{{ gxqm }}</pre>
        </div>
        <div class="page3">
          <div class="li czj" :class="{ liB: activePage === 1 }" @click="activePage = 1">
            成长记<span style="padding-left: 5px">{{ czjNum }}</span>
          </div>
          <div class="li czj" :class="{ liB: activePage === 2 }" @click="isLongText">
            长文<span style="padding-left: 5px">{{ longTextCount }}</span>
          </div>
          <div class="li czj" :class="{ liB: activePage === 3 }" @click="isLifeRecord">
            美图<span style="padding-left: 5px">{{ lifeRecordCount }}</span>
          </div>
          <div class="li xh" :class="{ liB: activePage === 4 }" @click="isXh">
            喜欢<span style="padding-left: 5px">{{ xhNum }}</span>
          </div>
          <!-- <img src="../../assets/record/record_page3_px.png" class="px"> -->
        </div>
        <div class="page4" v-if="checkIsActivePageEmpty">
          <van-empty
            :image="require('../../assets/index/empty_status.png')"
            description="这里空落落的"
            style="margin-top: 30px;"
          ></van-empty>
        </div>
        <div class="page4" v-else>
          <template v-if="activePage === 1">
            <div
              class="box"
              v-for="item in page4_list"
              :key="'plant' + item.plantId"
              @click="
                $router.push({
                  path: '/html/app/plant',
                  query: { id: item.plantId },
                })
              "
            >
              <div class="tit">
                <van-icon
                  name="like-o"
                  style="line-height: 200%; margin-left: 6px"
                />
                <span style="line-height: 200%; margin-left: 25px">{{
                  item.likeCount
                }}</span>
              </div>
              <van-image
                width="100%"
                height="100%"
                fit="cover"
                :src="item.cover.url"
              />
            </div>
          </template>
          <template v-else-if="activePage === 2">
            <div
              class="box"
              v-for="item in pageLongText"
              :key="'longtext' + item.plantId"
              @click="
                $router.push({
                  path: '/html/app/longtext',
                  query: { id: item.plantId },
                })
              "
            >
              <div class="tit">
                <van-icon
                  name="like-o"
                  style="line-height: 200%; margin-left: 6px"
                />
                <span style="line-height: 200%; margin-left: 25px">{{
                  item.likeCount
                }}</span>
              </div>
              <van-image
                width="100%"
                height="100%"
                fit="cover"
                :src="item.cover.url"
              />
            </div>
          </template>
          <template v-else-if="activePage === 3">
            <div
              class="box"
              v-for="item in pageLifeRecord"
              :key="'liferecord' + item.plantId"
              @click="
                $router.push({
                  path: '/html/app/liferecord',
                  query: { id: item.plantId },
                })
              "
            >
              <div class="tit">
                <van-icon
                  name="like-o"
                  style="line-height: 200%; margin-left: 6px"
                />
                <span style="line-height: 200%; margin-left: 25px">{{
                  item.likeCount
                }}</span>
              </div>
              <van-image
                width="100%"
                height="100%"
                fit="cover"
                :src="item.cover.url"
              />
            </div>
          </template>
          <template v-else>
            <div
              class="box"
              v-for="item in page4_like"
              :key="'like' + item.plantId"
              @click="
                $router.push({
                  path: detailUrls[item.functionType] || detailUrls[0],
                  query: { id: item.plantId },
                })
              "
            >
              <div class="tit">
                <van-icon
                  name="like-o"
                  style="line-height: 200%; margin-left: 6px"
                />
                <span style="line-height: 200%; margin-left: 25px">{{
                  item.likeCount
                }}</span>
              </div>
              <van-image
                width="100%"
                height="100%"
                fit="cover"
                :src="item.cover.url"
              />
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import TopBanner from "../../components/TopBanner.vue";
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
export default {
  name: "homePage",
  mounted() {
    this.appUrl.onlyMo(); //只允许移动端打开
    document.title = this.record_title;
    if (
      this.$route.query.id == null ||
      this.$route.query.id == undefined ||
      this.$route.query.id == ""
    ) {
      this.appUrl.alertErr("无权访问");
      return;
    } else {
      this.userId = this.$route.query.id;
      this.getUserInform(this.userId);
    }
    this.getPlantList(this.userId, this.page_num_list, this.page_size_list);
  },
  components: {
    TopBanner,
  },
  data() {
    return {
      record_title: "个人主页",
      page_num_list: 1,
      page_size_list: 20,
      listBool: true,
      page_num_like: 1,
      page_size_like: 20,        
      likeBool: true,
      userImage: null,
      userName: null,
      userFrom: {
        provinceName: '',
        cityName: '',
        areaName: ''
      },
      userAge: null,
      userSex: null,
      userId: null,
      fsNum: null,
      gzNum: null,
      hzNum: null,
      gxqm: null,
      czjNum: null,
      xhNum: null,
      page4_list: [],
      page4_like: [],
      longTextCount: null,
      lifeRecordCount: null,
      activePage: 1,
      pageLongText: [],
      pageLifeRecord: [],
      longTextPageNum: 1,
      lifeRecordPageNum: 1,
      functionTypeSize: 20,
      longTextBool: true,
      lifeRecordBool: true,
      detailUrls: ['/html/app/plant', '/html/app/longtext', '/html/app/liferecord']
    };
  },
  computed: {
    checkIsActivePageEmpty() {
      const listKey = ['page4_list', 'pageLongText', 'pageLifeRecord', 'page4_like']
      return this[listKey[this.activePage - 1]].length === 0
    }
  },
  methods: {
    scrollBottom(e) {
      if (this.activePage === 1) {
        if (this.page4_list.length % this.page_size_list != 0) {
          this.listBool = false;
        }
        if (this.listBool) {
          if (
            e.srcElement.scrollTop + e.srcElement.offsetHeight >
            e.srcElement.scrollHeight - 50
          ) {
            this.listBool = false;
            this.getPlantList(
              this.userId,
              this.page_num_list,
              this.page_size_list
            );
          }
        }
      }  else if (this.activePage === 2) {
        if (this.pageLongText.length % this.functionTypeSize != 0) {
          this.longTextBool = false;
        }
        if (this.longTextBool) {
          if (
            e.srcElement.scrollTop + e.srcElement.offsetHeight >
            e.srcElement.scrollHeight - 50
          ) {
            this.longTextBool = false;
            this.getLongText(
              this.userId,
              this.longTextPageNum,
              this.functionTypeSize
            );
          }
        }
      } else if (this.activePage === 3) {
        if (this.pageLifeRecord.length % this.functionTypeSize != 0) {
          this.lifeRecordBool = false;
        }
        if (this.lifeRecordBool) {
          if (
            e.srcElement.scrollTop + e.srcElement.offsetHeight >
            e.srcElement.scrollHeight - 50
          ) {
            this.lifeRecordBool = false;
            this.getLifeRecord(
              this.userId,
              this.page_num_like,
              this.functionTypeSize
            );
          }
        }
      } else if (this.activePage === 4) {
        if (this.page4_like.length % this.page_size_like != 0) {
          this.likeBool = false;
        }
        if (this.likeBool) {
          if (
            e.srcElement.scrollTop + e.srcElement.offsetHeight >
            e.srcElement.scrollHeight - 50
          ) {
            this.likeBool = false;
            this.getPlantLike(
              this.userId,
              this.page_num_like,
              this.page_size_like
            );
          }
        }
      }
    },
    isXh() {
      this.activePage = 4;
      if (this.page4_like.length == 0) {
        this.getPlantLike(this.userId, this.page_num_like, this.page_size_like);
      }
    },
    isLongText() {
      this.activePage = 2;
      if (this.pageLongText.length == 0) {
        this.getLongText(this.userId, this.longTextPageNum, this.functionTypeSize);
      }
    },
    isLifeRecord() {
      this.activePage = 3;
      if (this.pageLifeRecord.length == 0) {
        this.getLifeRecord(this.userId, this.lifeRecordPageNum, this.functionTypeSize);
      }
    },
    updateList(list) {
      for (let i = 0; i < list.length; i++) {
        this.page4_list.push(list[i]);
      }
    },
    updateLike(list) {
      for (let i = 0; i < list.length; i++) {
        this.page4_like.push(list[i]);
      }
    },
    updateListFunctionType(paramName, list) {
      for (let i = 0; i < list.length; i++) {
        this[paramName].push(list[i]);
      }
    },    
    getPlantList(id, num, size) {
      //成长记
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "正在加载",
      });
      this.$axios
        .get(
          `${this.apiUrl.url_web_plantlist_v2}?id=${id}&pageNum=${num}&pageSize=${size}`
        )
        .then((res) => {
          Toast.clear();
          if (res.data.code == 200) {
            if (
              res.data.data.list != null &&
              res.data.data.list != undefined &&
              res.data.data.list.length > 0
            ) {
              this.updateList(res.data.data.list);
              this.page_num_list++;
              this.listBool = true;
            }
          } else {
            this.appUrl.alertErr(res.data.msg);
          }
        })
        .catch(() => {
          Toast.clear();
          this.appUrl.alertErr("网络异常");
          console.log("err");
        });
    },
    getPlantLike(id, num, size) {
      //喜欢
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "正在加载",
      });
      this.$axios
        .get(
          `${this.apiUrl.url_web_plantlike}?id=${id}&pageNum=${num}&pageSize=${size}`
        )
        .then((res) => {
          Toast.clear();
          if (res.data.code == 200) {
            if (
              res.data.data.list != null &&
              res.data.data.list != undefined &&
              res.data.data.list.length > 0
            ) {
              this.updateLike(res.data.data.list);
              this.page_num_like++;
              this.likeBool = true;
            }
          } else {
            this.appUrl.alertErr(res.data.msg);
          }
        })
        .catch(() => {
          Toast.clear();
          this.appUrl.alertErr("网络异常");
          console.log("err");
        });
    },
    getLongText(id, num, size) {
      // 长文
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "正在加载",
      });
      this.$axios
        .get(
          `${this.apiUrl.url_web_plantlist_v2}?id=${id}&pageNum=${num}&pageSize=${size}&functionType=${1}`
        )
        .then((res) => {
          Toast.clear();
          if (res.data.code == 200) {
            if (
              res.data.data.list != null &&
              res.data.data.list != undefined &&
              res.data.data.list.length > 0
            ) {
              this.updateListFunctionType('pageLongText', res.data.data.list);
              this.longTextPageNum++;
              this.longTextBool = true;
            }
          } else {
            this.appUrl.alertErr(res.data.msg);
          }
        })
        .catch(() => {
          Toast.clear();
          this.appUrl.alertErr("网络异常");
          console.log("err");
        });
    },
    getLifeRecord(id, num, size) {
      // 美图
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "正在加载",
      });
      this.$axios
        .get(
          `${this.apiUrl.url_web_plantlist_v2}?id=${id}&pageNum=${num}&pageSize=${size}&functionType=${2}`
        )
        .then((res) => {
          Toast.clear();
          if (res.data.code == 200) {
            if (
              res.data.data.list != null &&
              res.data.data.list != undefined &&
              res.data.data.list.length > 0
            ) {
              this.updateListFunctionType('pageLifeRecord', res.data.data.list);
              this.lifeRecordPageNum++;
              this.lifeRecordBool = true;
            }
          } else {
            this.appUrl.alertErr(res.data.msg);
          }
        })
        .catch(() => {
          Toast.clear();
          this.appUrl.alertErr("网络异常");
          console.log("err");
        });
    },
    getUserInform(id) {
      //个人信息
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "正在加载",
      });
      this.$axios
        .get(`${this.apiUrl.url_web_info_v2}?id=${id}`)
        .then((res) => {
          if (res.data.code == 200) {
            this.userImage = res.data.data.avatar;
            this.userName = res.data.data.nickname;
            this.userFrom = {
              provinceName: res.data.data.place.provinceName,
              cityName: res.data.data.place.cityName,
              areaName: res.data.data.place.areaName
            }
            this.userAge = `${res.data.data.age}岁`;
            this.userSex = res.data.data.sex == 1 ? "男" : "女";
            this.userId = res.data.data.uid;
            this.gzNum = res.data.data.fansCount;
            this.fsNum = res.data.data.fansCount;
            this.gzNum = res.data.data.followCount;
            this.hzNum = res.data.data.likeCount;
            this.gxqm = res.data.data.signature;
            this.czjNum = res.data.data.publishPlantCount;
            this.xhNum = res.data.data.likePlantCount;
            this.longTextCount = res.data.data.longTextCount;
            this.lifeRecordCount = res.data.data.lifeRecordCount;
          } else {
            this.appUrl.alertErr(res.data.msg);
          }
        })
        .catch(() => {
          // Toast.clear();
          this.appUrl.alertErr("网络异常");
        });
    },
  },
};
</script>

<style scoped>
.cont {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
#homePage {
  min-height: calc(100% - 48px);
  background: #f7f7fa;
}
#homePage .page1 {
  position: relative;
  margin-top: 48px;
  background: #ffffff;
  width: 100%;
  height: 0;
  left: 0;
  top: 0;
}
#homePage  pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-weight: 500;
}
#homePage .page1[data-ratio="page1"] {
  padding-top: calc(100% * 156 / 375);
}
#homePage .page1 .img {
  position: absolute;
  top: calc(100% * 20 / 156);
  left: calc(100% * 15 / 375);
}
#homePage .page1 .txt1 {
  position: absolute;
  width: calc(100% * 170 / 375);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 800;
  color: #26262a;
  line-height: 25px;
  top: calc(100% * 24 / 156);
  left: calc(100% * 106 / 375);
}
#homePage .page1 .txt2 {
  position: absolute;
  top: calc(100% * 52 / 156);
  left: calc(100% * 106 / 375);
  font-size: 13px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #80869d;
  line-height: 17px;
}
#homePage .page1 .txt3 {
  padding: 0 20px;
}
#homePage .page1 .txt5 {
  position: absolute;
  top: calc(100% * 71 / 156);
  left: calc(100% * 106 / 375);
  font-size: 13px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #80869d;
  line-height: 17px;
}
#homePage .border {
  position: absolute;
  width: calc(100% * 345 / 375);
  height: 1px;
  border-bottom: 1px solid #f7f7fa;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
#homePage .grnum .num {
  position: absolute;
  font-size: 18px;
  font-family: Bebas;
  color: #26262a;
  line-height: 24px;
}
#homePage .grnum .txt {
  position: absolute;
  font-size: 12px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #80869d;
  line-height: 17px;
}
#homePage .fs .num {
  top: calc(100% * 106 / 156);
  left: calc(100% * 26 / 375);
  transform: translate(-50%, 0);
}
#homePage .gz .num {
  top: calc(100% * 106 / 156);
  left: calc(100% * 90 / 375);
  transform: translate(-50%, 0);
}
#homePage .hz .num {
  top: calc(100% * 106 / 156);
  left: calc(100% * 154 / 375);
  transform: translate(-50%, 0);
}
#homePage .fs .txt {
  top: calc(100% * 131 / 156);
  left: calc(100% * 26 / 375);
  transform: translate(-50%, 0);
}
#homePage .gz .txt {
  top: calc(100% * 131 / 156);
  left: calc(100% * 90 / 375);
  transform: translate(-50%, 0);
}
#homePage .hz .txt {
  top: calc(100% * 131 / 156);
  left: calc(100% * 154 / 375);
  transform: translate(-50%, 0);
}
#homePage .page2 {
  background: #ffffff;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 5px calc(100% * 15 / 375) 15px calc(100% * 15 / 375);
  font-size: 17px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #26262a;
  line-height: 24px;
}
#homePage .page2 pre {
  font-size: 15px;
  font-weight: 500;
  color: #26262a;
  line-height: 20px;
}
#homePage .page3 {
  background-color: #ffffff;
  padding: 0 calc(100% * 15 / 375);
  position: relative;
}
#homePage .page3 .li {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #80869d;
  min-width: 60px;
  height: 26px;
  display: inline-block;
  line-height: 21px;
  vertical-align: top;
  text-align: center;
  margin-right: 20px;
}
#homePage .page3 .liB {
  font-weight: 600;
  color: #26262a;
  border-bottom: 2px solid #26262a;
}
#homePage .page3 .px {
  position: absolute;
  width: 17px;
  top: 50%;
  right: calc(100% * 15 / 375);
  transform: translate(0, -50%);
}
#homePage .page4 {
  padding-top: 10px;
}
#homePage .page4 .box {
  width: calc((100% - 6px) / 3);
  height: 0;
  padding-top: calc((100% - 6px) / 3 * 160 / 123);
  position: relative;
  display: inline-block;
  border-radius: 2px;
  overflow: hidden;
  background: #dbdeeb;
  margin: 0 3px 3px 0;
  vertical-align: top;
}
#homePage .page4 .box:nth-child(3n) {
  margin: 0;
}
#homePage .page4 .box * {
  position: absolute;
}
#homePage .page4 .box .tit {
  width: 100%;
  left: 0;
  bottom: 0;
  height: calc(100% * 32 / 160);
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f7f7fa;
  line-height: 200%;
  z-index: 10;
  background: linear-gradient(
    180deg,
    rgba(58, 58, 58, 0) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
}
#homePage .page4 .box>>>.van-image {
  top: 0;
  left: 0;
}
</style>
