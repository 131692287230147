<template>
  <div class="top_banner">
    <div class="dom1">
      <van-image
        width="42"
        :src="require('../assets/record/record_page1_logo.png')"
        class="logo"
      />
    </div>
    <div class="dom2">一键记录植物成长</div>
    <div class="dom3" @click="dow_app">下载App</div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "TopBanner",
  methods: {
    dow_app() {
      //下载app
      var ua = navigator.userAgent.toLowerCase();
      //系统判断
      if ((ua.indexOf('qq')>-1 && ua.indexOf('mqqbrowser')<0) ||
        ua.match(/MicroMessenger/i) == 'micromessenger') {
          // qq 或微信浏览器
        this.$emit('toggle')
      } else if (ua.match(/iPhone/i) == "iphone") {
        //iphone
        console.log(ua.match(/iPhone/i));
        window.open(this.appUrl.down_url_iphone);
      } else if (ua.match(/Android/i) == "android") {
        //android
        console.log(ua.match(/Android/i));
        window.open(this.appUrl.down_url_android);
      } else {
        console.log("请使用移动端登录");
        Toast.fail("请前往移动端下载");
      }
    }
  },
};
</script>
<style scoped>
.top_banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 48px;
  background: #26262a;
  z-index: 999;
}
.top_banner .dom1 {
  position: absolute;
  width: 60px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #ffffff;
  top: 0;
  bottom: 0;
  left: 10px;
  margin: auto;
}
.top_banner .dom1 .logo {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}
.top_banner .dom2 {
  position: absolute;
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
  left: 82px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.top_banner .dom3 {
  position: absolute;
  width: 72px;
  height: 28px;
  background: #ffffff;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 600;
  color: #26262a;
  text-align: center;
  line-height: 28px;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
}
</style>
